import { createRouter, createWebHistory } from 'vue-router'

import Validation from './modules/validation'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...Validation]
})

router.beforeEach(({ query, path }, from) => {
  let isValidationPath = /validacao*/g

  isValidationPath = isValidationPath.test(path)

  if (!isValidationPath) {
    return {
      name: 'validation-login',
      query,
      params: {
        token: path
      }
    }
  }
})

export default router
