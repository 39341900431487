<template>
  <div class="validation__header fade-in">
    <img class="validation__logo" :src="logo" :alt="businessName" />

    <div v-if="hasSteps" class="validation__steps">
      <div
        v-for="(step, index) in Array.from({ length: times })"
        :key="index"
        :class="[{ done: index < current }, { current: index === current }]"
        class="validation__step"
      >
        <i
          :class="[
            { 'fas fa-check-circle': current === times - 1 || index < current },
            { 'fas fa-exclamation-circle': index === current && current !== times - 1 },
            { 'far fa-circle': index > current },
          ]"
        />
      </div>
    </div>

    <h3 v-if="hasTitle" class="validation__title">
      <slot />
    </h3>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BaseHeader',
  props: {
    times: {
      type: Number,
      default: 7,
      required: false
    },
    current: {
      type: Number,
      default: 0,
      required: false
    },
    hasSteps: {
      type: Boolean,
      default: true,
      required: false
    },
    hasTitle: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme.theme
    }),
    logo () {
      return `${this.theme.logo?.desktop}` || ''
    },
    businessName () {
      return this.theme.business?.name || ''
    }
  },
  watch: {
    times () {
      this.setSteps()
    }
  },
  created () {
    this.setSteps()
  },
  mounted () {
    this.setSteps()
  },
  methods: {
    setSteps () {
      document.documentElement.style.setProperty('--steps-counter', this.times)
    }
  }
}
</script>

<style lang="scss" scoped>
$recuo: 30px;

.validation {
  &__header {
    padding: 0 $recuo;
    align-self: flex-end;

    &.fade-in {
      animation: fadeIn 1s linear;

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
  }

  &__title {
    display: block;

    font: {
      family: Montserrat-Bold;
    }
    color: $color-secondary;
    text-align: center;

    span {
      color: $color-primary;
      text-transform: uppercase;
    }
  }

  &__logo {
    margin: 0 auto;

    display: block;
    width: 45%;
    max-width: 200px;
    height: auto;

    color: $color-primary;
    text-align: center;

    align-self: flex-end;
  }

  &__steps {
    margin: #{$recuo / 2} auto;

    display: grid;
    width: 100%;
    height: 30px;

    grid-template-columns: repeat(var(--steps-counter), 1fr);
    justify-content: center;
    align-items: center;
  }

  &__step {
    $base: #777;
    $done: $color-secondary;
    $current: $color-primary;
    $canvas: $color-light;

    position: relative;

    &.done {
      * {
        color: $done;
      }

      &::before {
        background-color: $done !important;
      }
    }

    &.current {
      * {
        color: $done;
      }
    }

    // dot
    i {
      position: absolute;
      top: 50%;
      left: 50%;

      color: $base;

      transform: translate(-50%, -50%);
    }

    // track
    &:not(:last-child)::before {
      content: '';

      position: absolute;
      top: 50%;
      left: calc(50% + 7px);

      width: calc(100% - 14px);
      height: 4px;
      background-color: $base;

      transform: translateY(-50%);
    }

    // Canvas :: dot
    &::after {
      $size: 24px;

      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;

      display: block;
      width: $size;
      height: $size;
      border-radius: 50%;
      box-sizing: border-box;
      background-color: $canvas;
      box-shadow: inset 0 0 2px rgba(#000, 0.5);

      transform: translate(-50%, -50%);
    }

    // Canvas :: track
    &:last-child::before {
      content: '';

      position: absolute;
      top: 50%;
      right: 50%;
      z-index: -4;

      width: calc(100% * calc(var(--steps-counter) - 1));
      height: 8px;
      background-color: $canvas;
      box-shadow: inset 0 0 2px rgba(#000, 0.5);

      transform: translateY(-50%);
    }
  }
}
</style>
