<template>
  <div class="credit-card-page">
    <validation-header :current="3">Dados do cartão</validation-header>

    <div class="credit-card-page__container slide-in">
      <credit-card-figure
        class="mb-3"
        :card-number="card.number"
        :card-owner="card.owner"
        :card-date="card.date"
        :card-verification-code="card.security_code"
      />

      <div class="credit-card-page__content credit-card-form">
        <v-validate-observer v-slot="{ handleSubmit }">
          <form
            class="credit-card-page__form"
            @submit.prevent="handleSubmit(createCreditCardToken)"
          >
            <v-validate
              v-slot="{ errors, field }"
              rules="required|length:19"
              name="Número do cartão"
            >
              <base-input
                v-bind="field"
                :model-value="card.number"
                :has-error="!!errors[0]"
                :first-error="errors[0]"
                type="tel"
                format="#### #### #### ####"
                @input="card.number = field.value"
              >
                <span>Número do cartão</span>
                <span>*</span>
              </base-input>
            </v-validate>

            <v-validate
              v-slot="{ errors, field }"
              rules="required"
              name="Nome no cartão"
            >
              <base-input
                v-bind="field"
                :model-value="card.owner"
                :has-error="!!errors[0]"
                :first-error="errors[0]"
                type="text"
                @input="card.owner = field.value"
              >
                <span>Nome no cartão</span>
                <span>*</span>
              </base-input>
            </v-validate>

            <div class="divided-inputs">
              <v-validate
                v-slot="{ errors, field }"
                rules="required|length:7"
                name="Data de Validade"
              >
                <base-input
                  v-bind="field"
                  :model-value="card.date"
                  :has-error="!!errors[0]"
                  :first-error="errors[0]"
                  type="tel"
                  placeholder="MM/AAAA"
                  format="##/####"
                  @input="card.date = field.value"
                >
                  <span>Data de Validade</span>
                  <span>*</span>
                </base-input>
              </v-validate>

              <v-validate
                v-slot="{ errors, field }"
                rules="required|length:3"
                name="CVC"
              >
                <base-input
                  v-bind="field"
                  :model-value="card.security_code"
                  :has-error="!!errors[0]"
                  :first-error="errors[0]"
                  type="tel"
                  format="###"
                  @input="card.security_code = field.value"
                >
                  <span>CVC</span>
                  <span>*</span>
                </base-input>
              </v-validate>
            </div>

            <div class="credit-card-page__ctrl">
              <button
                class="button btn is-primary"
                type="submit"
              >
                Salvar
              </button>
            </div>
          </form>

          <div v-if="message.error" class="alert mt-5">
            <p class="has-text-danger has-background-danger-light p-3">
              {{ message.text }}
            </p>
          </div>
        </v-validate-observer>
      </div>
    </div>
  </div>
</template>
<script>

import Card from '@/lib/api/Card'

import BaseInput from '@/components/BaseInput'
import CreditCardFigure from '@/components/CreditCardFigure'
import ValidationHeader from '@/components/BaseHeader'

import navigatorCompatibility from '@/mixins/navigatorCompatibility'
import loadingMixin from '@/mixins/loading'

import { mapState } from 'vuex'
import _ from 'lodash'
import * as Sentry from '@sentry/vue'

export default {
  name: 'AddCreditCard',
  components: {
    CreditCardFigure,
    BaseInput,
    ValidationHeader
  },
  mixins: [navigatorCompatibility, loadingMixin],
  data: () => ({
    card: {
      number: '',
      owner: '',
      date: '',
      security_code: ''
    },
    message: {
      error: false,
      text: null
    }
  }),
  computed: {
    ...mapState({
      account: (state) => state.validation.account
    }),
    document () {
      return (this.account.document ?? '').replace(/\D/g, '')
    },
    expiration_date () {
      return (this.card.date ?? '').split('/')
    },
    card_number () {
      return (this.card.number ?? '').split(' ').join('')
    },
    params () {
      const { owner: holder_name, security_code } = this.card
      const { account_id } = this.account
      const [expiration_month, expiration_year] = this.expiration_date
      const { card_number, document } = this

      return {
        document,
        account_id,
        holder_name,
        card_number,
        security_code,
        expiration_month,
        expiration_year
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => vm.routeProtection({ vm, to }))
  },
  methods: {
    async createCreditCardToken () {
      const api = new Card()

      this.loading = true
      this.message.error = false

      await api
        .createCreditCardToken(this.params)
        .then(() => this.nextStep())
        .catch((err) => this.handleError(err || { error: err }))
        .finally(() => {
          this.loading = false
        })
    },
    nextStep () {
      const type = 'selfie'
      const side = 'unico'
      const mode = 'user'
      const name = 'validation-card-verification'

      this.$router.push({ name, params: { type, side, mode } })
    },
    handleError (err) {
      this.message.text = _.isString(err)
        ? err
        : err?.error?.response?.data?.error?.message ||
          err?.response?.data?.error?.message ||
          err?.error?.message ||
          err?.message ||
          'Ocorreu um problema durante sua solicitação'

      Sentry.captureMessage(new Error('consult'), {
        tags: {
          ...this.account
        }
      })

      this.message.error = true
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';

.alert {
  padding: 0 30px;
}

.credit-card-page.is--full {
  padding: 12px;
  overflow-x: clip;
}

.credit-card-page {
  @include base();

  &__container,
  &__content {
    padding: 1rem !important;
  }

  &__content {
    border: 1px solid #e5e5e5;
    background-color: #f8f8f8;
    border-radius: 10px 10px 0 0;

    .divided-inputs {
      display: flex;
      gap: 12px;
    }
  }

  &__form {
    display: grid;

    grid-template-columns: 1fr;
    row-gap: 30px;

    &::v-deep {
      .control {
        margin-bottom: 0;
      }
    }
  }
}
</style>
