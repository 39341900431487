import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      $_loading: (state) => state.app.loading
    }),
    loading: {
      get () {
        return this.$_loading
      },
      set (payload) {
        this.setLoading(payload)
      }
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'app/SET_LOADING'
    })
  }
}
