import Login from '@/views/Login.vue'
import Documents from '@/views/Documents.vue'
import PhotoSelfie from '@/views/PhotoSelfie.vue'
import PhotoType from '@/views/PhotoType.vue'
import PhotoDocs from '@/views/PhotoDocs.vue'
import Consult from '@/views/Consult.vue'
import Final from '@/views/Final.vue'
import AddCreditCard from '@/views/AddCreditCard.vue'
import CardVerification from '@/views/CardVerification.vue'

const path = '/validacao'
const name = 'validation'

export default [
  {
    path: `${path}/:token`,
    name: `${name}-login`,
    component: Login
  },
  {
    path: `${path}/:token/dados-pessoais`,
    name: `${name}-documents`,
    component: Documents
  },
  {
    path: `${path}/:token/cartao-credito`,
    name: 'add-credit-card',
    component: AddCreditCard
  },
  {
    path: `${path}/:token/cartao-verificacao`,
    name: `${name}-card-verification`,
    component: CardVerification
  },
  {
    path: `${path}/:token/foto-documento/selfie`,
    name: `${name}-photo-selfie`,
    component: PhotoSelfie
  },
  {
    path: `${path}/:token/foto-documento`,
    name: `${name}-photo-type`,
    component: PhotoType
  },
  {
    path: `${path}/:token/foto-documento/:type/:side`,
    name: `${name}-photo-docs`,
    props: (route) => {
      const { type, side } = route.params
      return { docType: type, docSide: side }
    },
    component: PhotoDocs
  },
  {
    path: `${path}/:token/confirmar`,
    name: `${name}-consult`,
    component: Consult
  },
  {
    path: `${path}/:token/sucesso`,
    name: `${name}-final`,
    component: Final,
    props: true
  }
]

/**
 * Fluxo
 * - Login
 * - Dados Pessoais
 * - Confirmar
 * - Cartão de Crédito (prod 8)
 * - Cartão de Verificação (prod 8)
 * - Foto Selfie
 * - Tipo de Documento
 * - Foto Documento
 * - Sucesso
 */
