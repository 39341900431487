<template>
  <div class="credit-card-figure">
    <div class="card-icon">
      <i class="fa" :class="creditCardIcon" />
    </div>
    <div class="card-number">
      <span>{{ formattedCardNumber }}</span>
    </div>
    <div class="card-owner">
      <span>{{ formattedName }}</span>
    </div>
    <div class="card-date">
      <span class="card-date__title">Data de Validade</span>
      <span class="card-date__value">{{ cardDate || '00/0000' }}</span>
    </div>
    <div class="card-verification-code">
      <span class="card-verification-code__title">CVC</span>
      <span class="card-verification-code__value">{{
        cardVerificationCode
      }}</span>
    </div>
  </div>
</template>
<script>
import CreditCartType from 'credit-card-type'

export default {
  name: 'CreditCardFigure',
  data: () => ({
    creditCardType: {}
  }),
  props: {
    cardNumber: {
      required: false,
      type: [String],
      default: ''
    },
    cardDate: {
      required: false,
      type: [String],
      default: ''
    },
    cardVerificationCode: {
      required: false,
      type: [String],
      default: ''
    },
    cardOwner: {
      required: false,
      type: [String],
      default: ''
    }
  },
  watch: {
    formattedCardNumber (newNumber, oldNumber) {
      if (newNumber.length >= 4) {
        this.creditCardType = CreditCartType(newNumber)[0] ?? {}
      }
    }
  },
  computed: {
    creditCardIcon () {
      let newClass = ''

      if (this.creditCardType && this.creditCardType?.type) {
        newClass = `fa-cc-${this.creditCardType.type}`
      }

      return newClass
    },
    formattedCardNumber () {
      const shouldFormat = this.cardNumber || '0000000000000000'

      return shouldFormat
        ?.replace(/\D/g, '')
        ?.match(/.{1,4}/g)
        ?.join(' ')
    },
    formattedName () {
      return String(this.cardOwner || '').toUpperCase()
    }
  }
}
</script>
<style lang="scss">
.credit-card-figure {
  border-radius: 10px;
  height: 180px;
  display: grid;
  align-items: middle;
  justify-content: space-around;
  grid-template-areas:
    'card-icon card-icon'
    'card-number card-number'
    'card-owner card-owner'
    'card-date card-verification-code';
  color: rgb(217, 217, 217);
  font-weight: bold;
  background: hsla(196, 53%, 53%, 1);
  background: linear-gradient(
    17deg,
    hsla(196, 53%, 53%, 1) 0%,
    hsla(229, 64%, 34%, 1) 47%
  );

  .card-icon {
    grid-area: card-icon;
    text-align: right;
    font-size: 2rem;
    color: rgb(255, 255, 255);
  }
  .card-owner {
    min-height: 32px;
    grid-area: card-owner;
  }
  .card-number {
    grid-area: card-number;
    font-size: 1.25rem;
  }

  .card-date,
  .card-verification-code {
    height: 46px;

    span {
      display: block;
    }

    &__title {
      font-size: 0.7rem;
    }
  }

  .card-date {
    grid-area: card-date;
  }

  .card-verification-code {
    grid-area: card-verification-code;
  }
}
</style>
