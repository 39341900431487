<template>
  <div
    :class="{
      'has-error': hasError,
      'has-success': isPassed,
    }"
    class="control has-icons has-icons-right"
  >
    <template v-if="format">
      <input
        :value="modelValue"
        :class="{ 'has-value': modelValue }"
        v-mask="format"
        v-bind="$attrs"
        @input="$emit('input', $event.target.value)"
      />
    </template>

    <template v-else>
      <input
        :value="modelValue"
        :class="{ 'has-value': modelValue }"
        v-bind="$attrs"
        @input="$emit('input', $event.target.value)"
      />
    </template>

    <span
      v-if="hasIcon"
      :class="{
        'has-text-errorlight': hasError,
        'has-text-successlight': isPassed,
      }"
      class="icon is-right"
    >
    </span>
    <label class="label">
      <slot />
    </label>
    <i v-if="hasBar" class="bar" />
    <p v-if="hasError" class="help is-error">
      {{ firstError }}
    </p>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  name: 'BaseInput',
  directives: { mask },
  props: {
    modelValue: {
      required: true
    },
    hasError: {
      type: Boolean
    },
    hasIcon: {
      type: Boolean,
      default: true
    },
    firstError: {
      type: String
    },
    isPassed: {
      type: Boolean
    },
    format: {
      default: ''
    },
    hasBar: {
      type: Boolean,
      default: true
    }
  }
}
</script>
