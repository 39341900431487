<template>
  <div :class="{ loading: config.loading }" class="validation-final">
    <base-header
      :current="isCorban ? 3 : 3"
      :times="isCorban ? 3 : 6"
    >
      Obrigado!
    </base-header>

    <div v-if="message.error" class="alert">
      <p class="has-text-danger has-background-danger-light p-3">
        {{ message.text }}
      </p>
    </div>

    <div v-if="!message.error" class="validation-final__container slide-in">
      <div class="validation-final__content">
        <template v-if="config.loading">
          <span class="icon spinner">
            <i class="fas fa-spinner"></i>
          </span>
          <span>carregando ...</span>
        </template>
        <template v-else>
          <i class="far fa-check-circle" />
          <p>
            <strong>Dados salvos</strong>
          </p>
          <p>Seus dados foram salvos com sucesso.</p>
          <p>
            Tão logo eles sejam validados, um de nossos consultores seguirá o
            contato.
          </p>
          <p>Aguarde!</p>
        </template>
      </div>
      <div class="validation-final__ctrl" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import BaseHeader from '@/components/BaseHeader.vue'

import navigatorCompatibility from '@/mixins/navigatorCompatibility'

import ValidationAPI from '@/lib/api/Validation'

export default {
  name: 'ValidationFinal',
  components: {
    BaseHeader
  },
  mixins: [navigatorCompatibility],
  data: () => ({
    config: {
      loading: false
    },
    message: {
      error: false,
      text: null
    }
  }),
  computed: {
    hasTerms () {
      return this.$route.params.terms
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async (vm) => await vm.routeProtection({ vm, to }))
  },
  async mounted () {
    await this.check()
  },
  methods: {
    // handlers
    cleanAccount () {
      this.account = {}
    },
    handleError (err) {
      this.message.text = _.isString(err)
        ? err
        : err?.error?.response?.data?.error?.message ||
          err?.response?.data?.error?.message ||
          err?.error?.message ||
          err?.message ||
          'Ocorreu um problema durante sua solicitação'

      this.message.error = true
      this.config.loading = false
    },

    // api
    async check () {
      const api = new ValidationAPI()

      this.config.loading = true

      if (this.isCorban) {
        await api
          .checkCorban({ account_id: this.account.account_id })
          .catch((err) => this.handleError(err))
          .finally(() => {
            this.config.loading = false
          })
      } else {
        await api
          .check({ account_id: this.account.account_id })
          .then(() => this.cleanAccount())
          .catch(() => this.goToSelfie())
          .finally(() => {
            this.config.loading = false
          })
      }
    },

    // navigation
    goToSelfie () {
      const { query, params } = this.$route
      const name = 'validation-photo-selfie'
      const type = 'selfie'
      const side = 'unico'
      const mode = 'user'

      this.$router.push({
        name,
        params: { ...params, type, side, mode },
        query
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';

.validation-final {
  @include base();

  &__container {
    margin-bottom: 0;
  }

  &__content {
    color: $color-secondary;
    text-align: center;

    .far {
      margin-bottom: 10px;
      font: {
        size: 4em;
      }
      color: $color-primary;
    }
  }
}

.alert {
  padding: 0 30px;
}

.spinner {
  position: absolute;
  top: 20px;
  right: 20px;

  font-size: 2em;
  color: $color-primary;

  animation: 0.75s linear infinite spinner-border;
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}
</style>
