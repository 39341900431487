<template>
  <div
    :class="{ loading: config.loading }"
    class="validation-documents is--full"
  >
    <base-header :current="2">Confirmar dados</base-header>

    <div class="validation-documents__container slide-in">
      <div v-if="message.error" class="alert">
        <p class="p-5 has-text-danger has-background-danger-light">
          {{ message.text }}
        </p>
        <p>Ocorreu um problema durante a requisição dos dados</p>
        <p>Isso pode ocorrer devido a instabilidades momentâneas na rede</p>
        <p>Recarregue a página e tente novamente</p>

        <button class="button btn mt-5" @click.prevent="reload">
          <span>Recarregar</span>
          <span class="icon">
            <i class="fas fa-undo" />
          </span>
        </button>
      </div>
      <template v-else>
        <div class="validation-documents__content">
          <template v-if="config.loading">
            <span class="icon spinner">
              <i class="fas fa-spinner"></i>
            </span>
            <span>carregando ...</span>
          </template>

          <template v-else>
            <template
              v-for="({ label, value, signal, suffix }, index) in simulation"
              :key="index"
            >
              <div  class="control">
                <label>{{ label }}</label>
                <span v-if="signal" class="input">
                  {{ money(value) }}
                </span>
                <span v-else class="input">{{ value }} {{ suffix }}</span>
                <i class="bar" />
              </div>
            </template>

            <ul class="list mt-5">
              <li class="control">
                <label>Parcelas</label>
              </li>
              <li
                v-for="({ due_date, total_amount }, index) in periods"
                :key="index"
                class="list__item text"
              >
              <span v-if="isCreditCard" key="date-card">
                  {{ due_date.split('-').reverse().join('/').slice(3) }}:
                </span>
                <span v-else key="date-fgts">
                  {{ truncate(due_date, 4, '') }}:
                </span>
                <input :value="money(total_amount)" type="text" disabled />
              </li>
              <li class="control">
                <i class="bar" />
              </li>
            </ul>
          </template>
        </div>

        <div v-if="!config.loading" class="validation-documents__ctrl">
          <button class="button btn" @click="handleTerms(true)">
            <span>Aceito</span>
            <span class="icon">
              <i class="fas fa-check" />
            </span>
          </button>
          <button class="button btn is--secondary" @click="handleTerms(false)">
            <span>Não aceito</span>
            <span class="icon">
              <i class="fas fa-times" />
            </span>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState, mapActions } from 'vuex'
import * as Sentry from '@sentry/vue'

import QITechAPI from '@/lib/api/QITech'
import Card from '@/lib/api/Card'

import BaseHeader from '@/components/BaseHeader.vue'

import navigatorCompatibility from '@/mixins/navigatorCompatibility'

import { truncate } from '@/utils/filters'

const CREDIT_CARD_PRODUCT_ID = 8

export default {
  name: 'ValidationConsult',
  components: {
    BaseHeader
  },
  mixins: [navigatorCompatibility],
  data: () => ({
    info: {},
    form: {
      terms: false
    },
    message: {
      error: false,
      text: ''

    },
    config: {
      loading: false
    }
  }),
  computed: {
    ...mapState({
      account: (state) => state.validation.account
    }),
    isCreditCard () {
      return this.account.product_id === CREDIT_CARD_PRODUCT_ID
    },
    simulation () {
      const keys = Object.keys(this.info)
      let productType = 'Empréstimo saque-aniversário FGTS'

      if (keys.length === 0) return []

      if (this.isCreditCard) productType = 'Empréstimo Cartão de Crédito'

      return [
        {
          label: 'Tipo de produto',
          value: productType
        },
        {
          label: 'Valor a receber',
          value: this.info.disbursed_issue_amount,
          signal: 'money'
        },
        {
          label: 'Taxa de Juros',
          value: (this.info.monthly_rate * 100).toFixed(2),
          suffix: '% a.m.'
        },
        {
          label: 'Custo efetivo total',
          value: (this.info.cet * 100).toFixed(2),
          suffix: '% a.m.'
        }
      ]
    },
    periods () {
      const keys = Object.keys(this.info)

      if (keys.length === 0) return []

      if (!this.info.periods) return []

      return this.info.periods.map(({ due_date, total_amount, amount }) => ({
        due_date,
        total_amount: total_amount?.toFixed(2) || amount?.toFixed(2)
      }))
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => vm.routeProtection({ vm, to }))
  },
  mounted () {
    if (this.isCreditCard) this.creditCardSimulation()
    else this.consult()
  },
  methods: {
    ...mapActions({
      setAccount: 'validation/SET_ACCOUNT'
    }),

    // handlers
    setData ({ data }) {
      for (const key of Object.keys(this.info)) {
        delete this.info[key]
      }
      for (const key of Object.keys(data)) {
        this.info[key] = data[key]
      }
    },
    truncate (...args) {
      return truncate(...args)
    },
    money (value) {
      return parseFloat(value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    },
    formatDate (date) {
      return date.split('-').reverse().join('/')
    },
    handleError (err) {
      this.message.text = _.isString(err)
        ? err
        : err?.error?.response?.data?.error?.message ||
          err?.response?.data?.error?.message ||
          err?.error?.message ||
          err?.message ||
          'Ocorreu um problema durante sua solicitação'

      Sentry.captureMessage(new Error('consult'), {
        tags: {
          ...this.account
        }
      })

      this.message.error = true
      this.config.loading = false
    },

    // api
    async consult () {
      const api = new QITechAPI()
      const params = {
        document: (this.account.document || '').replace(/\D/g, ''),
        wish_amount: this.account.wish_amount || ''
      }

      this.config.loading = true
      this.message.error = false

      try {
        const { data } = await api.consult(params)

        if (data.data.permiteConsulta === false) {
          this.handleError({
            error:
              'Não foi possível realizar a consulta, por favor, tente novamente mais tarde.'
          })
        } else this.setData(data)
      } catch (err) {
        this.handleError(err || { error: err })
      } finally {
        this.config.loading = false
      }
    },
    async creditCardSimulation () {
      const api = new Card()
      const params = {
        account_id: this.account.account_id,
        document: (this.account.document || '').replace(/\D/g, '')
      }

      this.config.loading = true

      api
        .creditCardSimulation(params)
        .then((data) => this.setData(data.data))
        .catch((err) => this.handleError(err || { error: err }))
        .finally(() => {
          this.config.loading = false
        })
    },

    // navigation
    reload () {
      window.location.reload(true)
    },
    async handleTerms (state) {
      this.form.terms = state

      this.account = {
        ...this.account,
        terms: state
      }

      this.nextStep()
    },
    nextStep () {
      const has_warranty = this.account?.has_warranty ?? false
      const already_verified = this.account?.card_verified ?? false

      let name = 'validation-photo-selfie'

      if (this.isCreditCard) {
        if (!has_warranty) name = 'add-credit-card'
        else if (!already_verified) name = 'validation-card-verification'
      }

      this.$router.push({ name })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';

.validation-documents {
  @include base();

  [class*='__ctrl'] {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 30px;

    .is--secondary {
      background-color: $color-light;
      border-color: $color-light;
    }
  }
}

.alert {
  padding: 30px;
  text-align: center;

  p {
    + p {
      margin-top: 30px;
    }
  }
}

div.control {
  margin-bottom: 0;

  display: grid;
  width: 100%;

  grid-template-columns: 1fr;

  &:first-child {
    margin-top: 0;
  }

  + div.control {
    margin-top: 30px;
  }

  label {
    color: #616161;
  }

  span {
    display: block;
    border: none;
    height: auto;
    box-shadow: none;
    padding: 0.5em 0.125rem 0.75em;
    background-color: rgba(#000, 0);

    color: $color-secondary;
    line-height: 1em;
  }

  input {
    color: $color-secondary;
  }
}

.spinner {
  position: absolute;
  top: 20px;
  right: 20px;

  font-size: 2em;
  color: $color-primary;

  animation: 0.75s linear infinite spinner-border;
}

.list {
  margin: 0;
  padding: 0;

  &__item {
    $padding: 30px;

    position: relative;
    margin-bottom: 20px;

    display: flex;
    padding-left: $padding;

    &::before {
      $size: 10px;

      content: '';

      position: absolute;
      top: 50%;
      left: (($padding / 2) - ($size / 2));

      display: block;
      width: $size;
      height: $size;
      background-color: $color-primary;

      transform: translateY(-50%);
    }

    input,
    span {
      display: inline-block;

      font: {
        family: inherit;
        size: inherit;
      }
    }

    span {
      color: $color-secondary;
    }

    input {
      margin-left: 5px;

      border: none;
      background-color: rgba(#fff, 0);

      color: #444;
    }
  }
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}
</style>
