export const getters = {
  getSidesOptions (state) {
    const entries = Object.entries(state.sides)
    const data = {}

    for (const [key, value] of entries) {
      data[value] = key
    }

    return data
  }
}
