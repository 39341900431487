import themeFallback from '@/lib/data/theme.json'

export const actions = {
  async SET_THEME ({ commit }, { theme }) {
    if (theme.business) {
      await commit('SET_THEME', theme)
      return
    }

    try {
      let { business_info, styles } = theme

      if (typeof business_info === 'string') {
        business_info = JSON.parse(business_info)
        styles = JSON.parse(styles)
      }

      await commit('SET_THEME', {
        business: {
          ...business_info.business,
          name: theme.name,
          document: theme.document
        },
        edit: {
          show: {
            ...business_info.edit.show
          }
        },
        external_links: {
          ...business_info.external_links
        },
        validation: {
          ...business_info.validation_app
        },
        ...styles
      })
    } catch {
      await commit('SET_THEME', {
        business: {
          ...themeFallback.business_info.business,
          name: themeFallback.name,
          document: themeFallback.document
        },
        edit: {
          show: {
            ...themeFallback.business_info.edit.show
          }
        },
        external_links: {
          ...themeFallback.business_info.external_links
        },
        ...themeFallback.styles
      })
    }
  }
}
