import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

import { app } from './modules/app'
import { validation } from './modules/validation'
import { theme } from './modules/theme'

const persist = new VuexPersistence({
  key: 'vuex',
  storage: window.sessionStorage,
  reducer: (state) => ({
    validation: state.validation
  })
})

export default createStore({
  plugins: [persist.plugin],
  modules: {
    app,
    theme,
    validation
  }
})
