<template>
  <div :class="{ loading }" class="validation-login">
    <base-header :has-steps="false" :has-title="false" />

    <div class="validation-login__container fade-in">
      <div v-if="!isLogged" class="validation-login__content">
        <i
          :class="[expanded ? 'fa-times-circle' : 'fa-question-circle']"
          class="fas"
          @click="expanded = !expanded"
        />
        <p>Bem vindo ao Sistema de Validação {{ businessName }}</p>

        <span v-show="false">{{ form.document }}</span>

        <p v-if="expanded" class="expanded">
          Esse é um sistema simples e rápido, onde utilizamos seu CPF, Data de
          Nascimento e foto do documento RG ou CNH para validação da proposta.
        </p>

        <p v-if="isValid" class="mt-5">Vamos começar?</p>

        <div v-else class="alert">
          <p class="has-text-danger has-background-danger-light">
            Dispositivo incompatível
          </p>

          <p>
            Este dispositivo não oferece um ou mais recursos necessários para a
            validação.
          </p>

          <p>
            Para dispositivos móveis <strong>Android</strong>, considere
            utilizar o <strong>Google Chrome</strong>,
            <strong>Microsoft Edge</strong> ou <strong>Opera</strong>.
          </p>

          <p>
            Em caso de <strong>iOS</strong>, utilize o <strong>Safari</strong>
          </p>

          <button class="button btn" @click="back">
            <span>Retornar</span>
            <span class="icon">
              <i class="fas fa-home" />
            </span>
          </button>
        </div>
      </div>

      <template v-if="isValid && !isLogged">
        <v-validate-observer v-slot="{ handleSubmit }">
          <form
            class="validation-login__form"
            @submit.prevent="handleSubmit(submit)"
          >
            <v-validate
              v-slot="{ errors, field }"
              rules="required|CPF|length:14"
              name="CPF"
            >
              <base-input
                v-bind="field"
                :model-value="form.document"
                :has-error="!!errors[0]"
                :first-error="errors[0]"
                type="tel"
                maxlength="14"
                format="###.###.###-##"
                @input="form.document = field.value"
              >
                <span>CPF</span>
                <span class="required">*</span>
              </base-input>
            </v-validate>

            <v-validate
              v-slot="{ errors, field }"
              :rules="{ regex: regex.date, required: true }"
              name="Data de nascimento"
            >
              <base-input
                v-bind="field"
                :model-value="form.birthday"
                :has-error="!!errors[0]"
                :first-error="errors[0]"
                type="tel"
                maxlength="10"
                format="##/##/####"
                @input="form.birthday = field.value"
              >
                <span>Data de Nascimento</span>
                <span class="required">*</span>
              </base-input>
            </v-validate>

            <div>
              <button :disabled="loading" class="button btn" type="submit">
                <span>Validar</span>
                <span class="icon">
                  <i class="fas fa-sign-in-alt" />
                </span>
              </button>
            </div>
          </form>

          <div v-if="message.error" class="alert mt-5">
            <p class="has-text-danger has-background-danger-light p-3">
              {{ message.text }}
            </p>
          </div>
        </v-validate-observer>
      </template>

      <template v-if="isValid && isLogged">
        <template v-for="(alert, index) in fraudAlerts">
          <template v-if="alert.approved === false && index === fraudStep">
            <div
              class="fraud-alert fade-in"
              :class="alert.backgroundColor"
              :key="alert.step"
            >
              <figure class="fraud-alert__img">
                <img :src="alert.src" />
              </figure>
              <button class="button btn" type="submit" @click="goTo">
                <span>Prosseguir</span>
                <span class="icon">
                  <i class="fas fa-sign-in-alt" />
                </span>
              </button>
            </div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import BaseHeader from '@/components/BaseHeader.vue'
import BaseInput from '@/components/BaseInput'

import themeMixin from '@/mixins/themeMixin'
import navigatorCompatibility from '@/mixins/navigatorCompatibility'

import ValidationAPI from '@/lib/api/Validation'

export default {
  name: 'ValidationLogin',
  mixins: [themeMixin, navigatorCompatibility],
  components: {
    BaseHeader,
    BaseInput
  },
  data: () => ({
    form: {
      document: '',
      birthday: ''
    },
    regex: {
      date: /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/
    },
    message: {
      error: false,
      text: null
    },
    expanded: false,
    loading: false,
    isLogged: false,
    fraudStep: 0
  }),
  computed: {
    token () {
      return this.$route.params.token
    },
    fraudAlerts () {
      return [
        {
          src: `/img/nao-solicita-${this.domain}.jpeg`,
          alertName: 'Não solicita',
          backgroundColor: 'fraud-alert--secondary',
          approved: false
        },
        {
          src: `/img/nao-cobra-${this.domain}.jpeg`,
          alertName: 'Não Cobra',
          backgroundColor: 'fraud-alert--light',
          approved: false
        }
      ]
    },
    logo () {
      return this.theme.logo?.desktop || ''
    },
    businessName () {
      return this.theme.business?.name || ''
    }
  },
  methods: {
    // handlers
    setData ({ ...data }) {
      this.account = data
      this.isLogged = true
    },
    handleError ({ error }) {
      this.message.text =
        error?.response?.data?.error?.message ||
        error?.error?.response?.data?.error?.message ||
        error?.message ||
        error

      if (error?.errors) {
        for (const key in error?.errors) {
          if (Object.hasOwnProperty.call(error?.errors, key)) {
            const [message] = error?.errors[key]
            this.message.text += message
          }
        }
      }

      this.message.error = true
    },
    getFormattedData () {
      return {
        document: this.form.document,
        birthdate: this.form.birthday,
        token: this.token
      }
    },

    // api
    async submit () {
      const api = new ValidationAPI()
      const data = this.getFormattedData()

      this.loading = true
      this.message.error = false
      this.message.text = null

      await api
        .login(data)
        .then(({ data }) => this.setData(data))
        .catch(({ response }) =>
          this.handleError(response?.data || { error: response })
        )
        .finally(() => {
          this.loading = false
        })
    },

    // navigation
    back () {
      this.$router.push('/')
    },
    goTo () {
      const name = 'validation-documents'

      this.fraudAlerts[this.fraudStep].approved = true
      this.fraudStep++

      if (_.every(this.fraudAlerts, { approved: true })) { this.$router.push({ name }) }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';
@import '@/assets/scss/src/_loading-skeleton.scss';

$recuo: 30px;

.validation-login {
  @include base();

  &__container {
    display: grid;
    row-gap: $recuo;

    &.fade-in {
      animation: fadeIn 1s linear;
    }
  }

  &__content {
    position: relative;

    color: $color-secondary;
    text-align: center;

    transition: all 0.35s ease;

    .expanded {
      margin-top: 24px;

      padding: 20px;
      border-radius: 5px;
      background-color: $color-light;
    }

    .fas[class*='circle'] {
      position: absolute;
      top: #{($recuo - 10) * (-1)};
      right: #{($recuo - 10) * (-1)};

      border-radius: 50%;
      overflow: hidden;

      font-size: 1.25em;

      cursor: pointer;
    }

    .alert {
      $recuo: 24px;

      margin: {
        top: $recuo;
        bottom: $recuo;
      }
    }

    .has-text-danger {
      padding: 10px 20px;
      border-radius: 5px;
    }

    .button {
      margin-top: $recuo;
    }
  }

  &__form {
    display: grid;

    grid-template-columns: 1fr;
    row-gap: $recuo + 10;

    .required {
      margin-left: 5px;
      color: $color-primary;
    }

    .button.is-text {
      margin-top: $recuo / 2;
    }

    &::v-deep {
      .control {
        margin-bottom: 0;
      }
    }
  }
}
.fraud-alert {
  min-width: calc(100% + $recuo * 2);
  min-height: calc(100% + $recuo * 2);
  margin-top: $recuo * (-1);
  margin-left: $recuo * (-1);
  border-radius: 10px;

  &.fade-in {
    animation: fadeIn 1s linear;
  }

  &--secondary {
    background-color: $color-secondary;
  }

  &--light {
    background-color: $color-light;
  }

  &__img {
    img {
      border-radius: 8px;
    }
  }
}

.button.is-text {
  margin: 0 auto;
  display: block;

  color: $color-secondary;
}
</style>
