<template>
  <router-view />
  <loader
    v-if="loading"
    :object="color.light"
    :color1="color.secondary"
    :color2="color.primary"
    :opacity="opacity"
    :bg="backgroundColor"
    size="5"
    speed="2"
    name="circular"
    objectbg="#999793"
  />
</template>

<script>
import _ from 'lodash'

import config from '@/lib/data/config'
import themeFallback from '@/lib/data/theme.json'

import themeMixin from '@/mixins/themeMixin'
import loadingMixin from '@/mixins/loading'

const fallbackColorLoading = {
  primary: '#d3d3d3',
  secondary: '#e5e5e5',
  light: '#f8f8f8'
}

export default {
  name: 'App',
  mixins: [themeMixin, loadingMixin],
  computed: {
    hasTheme () {
      return !!this.theme.business
    },
    color () {
      return this.theme?.color || fallbackColorLoading
    },
    opacity () {
      return this.hasTheme ? 80 : 100
    },
    backgroundColor () {
      return this.hasTheme ? '#343a40' : '#fff'
    },
    businessName () {
      return this.theme?.business?.name || themeFallback.name
    }
  },
  async mounted () {
    this.loading = true

    await this.loadTheme()

    this.loading = false
  },
  methods: {
    async loadTheme () {
      const version = sessionStorage.getItem(this.themeVersionKey)
      let theme = sessionStorage.getItem(this.themeKey)

      // check version
      const isSameVersion = version && _.isEqual(version, config.THEME_VERSION)

      // if already loaded in $store
      if (isSameVersion && this.theme.business) return

      // get valid theme
      theme =
        !isSameVersion || !theme ? await this.fetchTheme() : JSON.parse(theme)

      // set theme
      await this.setTheme({ theme })
      this.registerCSSVariables()

      // to save the formatted theme (this.theme)
      sessionStorage.setItem(this.themeVersionKey, config.THEME_VERSION)
      sessionStorage.setItem(this.themeKey, JSON.stringify(this.theme))
    }
  }
}
</script>

<style>
html {
  scroll-behavior: smooth;
}

body {
  overflow: hidden;
}

section {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
