// settings
import { Field, Form, defineRule, configure } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
import { messages as messagesPT_BR } from '@vee-validate/i18n/dist/locale/pt_BR.json'

// rules
import { required, regex, length } from '@vee-validate/rules'
import { validate as validateCPF } from 'gerador-validador-cpf'

// rules config
const rules = { required, regex, length }
Object.entries(rules).forEach(([key, value]) => defineRule(key, value))

defineRule('CPF', (value) => {
  if (validateCPF(value)) return true
  else return 'O CPF informado não é válido'
})

defineRule('alert_min', (value) => {
  if (parseFloat(value) >= 0.01) return true
  else return 'Preencha com o valor informado'
})

// messages
const messages = {}
Object
  .keys(rules)
  .forEach((key) => {
    messages[key] = messagesPT_BR[key]
  })
configure({
  generateMessage: localize('pt_BR', { messages })
})

// init
export {
  Field,
  Form
}
