<template>
  <div class="validation-photo is--full">
    <base-header :current="isCorban ? 1 : 3" :times="isCorban ? 3 : 6">
      Foto do Documento
    </base-header>

    <div class="validation-photo__container slide-in">
      <div class="validation-photo__content">
        <p>
          <strong>Tire a foto do seu documento</strong>
        </p>
        <img :src="icon.photo" alt="photo" class="photo" />
        <p>
          Escolha o
          <strong class="featured">{{ !form.type ? 'TIPO' : 'LADO' }}</strong>
          do documento
        </p>
      </div>

      <div v-if="!form.type" key="set-type" class="validation-photo__ctrl">
        <button
          v-for="({ type, label }, index) in list.types"
          :key="index"
          class="button btn"
          @click="setData('type', type)"
        >
          <span>Carteira {{ label }}</span>
        </button>
      </div>

      <div v-else key="set-side" class="validation-photo__ctrl">
        <template v-for="({ id, label }, index) in validSides" :key="index">
          <button class="button btn" @click="nextStep(id)">
            <span>{{
              form.type === 'CTPS' ? 'Páginas FOTO / CPF' : label
            }}</span>
          </button>
        </template>
      </div>
    </div>

    <div v-if="form.type" class="is-flex">
      <button class="button is-text is-small" @click="setData('type', null)">
        <span>Resetar</span>
      </button>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue'

import navigatorCompatibility from '@/mixins/navigatorCompatibility'

export default {
  name: 'ValidationPhotoType',
  components: {
    BaseHeader
  },
  mixins: [navigatorCompatibility],
  data: () => ({
    list: {
      types: [
        { label: 'CNH', type: 'CNH' },
        { label: 'RG', type: 'RG' },
        { label: 'RG (novo)', type: 'NEWRG' },
        { label: 'CTPS', type: 'CTPS' }
      ],
      sides: [
        { label: 'Frente / Verso', id: 'A' },
        { label: 'Aberto', id: 'C' }
      ]
    },
    icon: {
      photo: require('@/assets/img/add-image.png')
    },
    form: {
      type: null,
      side: null
    }
  }),
  computed: {
    validSides () {
      const { type } = this.form

      if (['CNH'].includes(type)) { return this.list.sides.filter(({ id }) => id === 'C') }

      if (['RG', 'NEWRG', 'CTPS'].includes(type)) { return this.list.sides.filter(({ id }) => id !== 'C') }

      return this.list.sides
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async (vm) => await vm.routeProtection({ vm, to }))
  },
  methods: {
    // handlers
    setData (key, value) {
      this.form[key] = value
    },

    // navigation
    nextStep (side) {
      const { type } = this.form
      const { query, params } = this.$route

      this.$router.push({
        name: 'validation-photo-docs',
        params: { ...params, type, side },
        query
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';

.validation-photo {
  @include base();

  &__container {
    height: auto !important;
  }

  &__content {
    background-color: #fff !important;

    color: $color-secondary;
    text-align: center;

    .photo {
      margin: 30px;

      width: 100px;
      height: 70px;
      object: {
        fit: cover;
        position: top;
      }
    }
  }

  &__ctrl {
    .button {
      + .button {
        margin-top: 30px;
      }
    }
  }
}

.is-text {
  margin: 0 auto;
}

.featured {
  color: $color-primary !important;
}
</style>
