import axios from 'axios'

import Base from './Base'

const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiT2N0YSJ9.8EJy7xvSqyeHhpSiR1Zh_SRnOxvUUdqEcgj-SH4pTGE'

export default class Card extends Base {
  constructor () {
    super()

    delete axios.defaults.headers.common.Authorization // prevent CORS errors
    delete axios.defaults.headers.common['X-Authorization'] // prevent CORS errors

    axios.defaults.headers.common = { Authorization: `Bearer ${token}` }

    this.api = axios
  }

  createCreditCardToken (params) {
    return this.api.post('/cards', { ...params })
  }

  creditCardSimulation (params) {
    return this.api.get('/cards/simulation', { params })
  }

  verification (data) {
    return this.api.post('/cards/verification', { ...data })
  }
}
