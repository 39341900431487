const ENVIRONMENT = process.env.VUE_APP_ENV
const API_KEY =
  'dUd4QCRb49kUoFqxd337BPorPphXllHxknY2crt1XckrxXkxkcakgj4fgnmOHSxz'
const THEME_VERSION = '2.0.1'

let BASE_URL = 'https://api.pdg.dev.local/v1'

if (ENVIRONMENT === 'production') {
  BASE_URL = 'https://pratadigital.com.br/sistema-cb/v1'
} else if (ENVIRONMENT === 'staging') {
  BASE_URL = 'https://api.bancoprata.com.br/v1'
  // BASE_URL = 'https://pratadigital.com.br/qa/v1'
}

export default {
  BASE_URL,
  API_KEY,
  THEME_VERSION,
  UNICO_PUBLIC_FOLDER_PATH: '/data'
}
